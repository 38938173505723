import { useMiddleware } from "@/store/middleware";

export default defineNuxtRouteMiddleware(async (to, from) => {
  const auth = useAuth();
  const config = useRuntimeConfig();
  const middleware = useMiddleware();

  //Check account status
  const account = ref(null);
  if (auth?.subdomain !== "app") {
    try {
      account.value = await middleware.account();
    } catch (e) {
      return navigateTo(`${config.public.primaryURL}/switch`, {
        external: true,
      });
    }
  }

  // Can not access unless in a subdomain
  if (
    auth?.subdomain === "app" &&
    account.value?.account_status === "ONBOARDING"
  ) {
    return navigateTo(`${config.public.primaryURL}/onbaording`);
  }

  // Can not access unless account is onboarding
  if (account.value?.account_status === "ONBOARDING") {
    // Can not access but select pages
    switch (to.path) {
      case "/onboarding/payment":
        return;
      case "/onboarding/contacts":
        return;
      case "/onboarding/agreement":
        return;
    }
  } else {
    return navigateTo("/hub/sessions");
  }
});
